import React from 'react'

const PNF = () => {
    return (
        <div className=' text-white' style={{ minHeight: "500px" }}>
            <center style={{top:'55px',fontSize: '150px', position: 'relative',zIndex:"500" }}>
                Page Not Available
            </center>
        </div>
    )
}

export default PNF